import React, { useState } from "react";
import classNames from "classnames";
import moment from "moment";
import localization from "moment/locale/ru";
import ImageWithCookie from "./ImageWithCookie";
import Popup from "reactjs-popup";
import TrainingPopup from "./TrainingPopup";

moment.locale("ru");

const GameFieldHeader = ({ data = {} }) => {
  const [isTrainingClicked, setIsTrainingClicked] = useState(false);
  const {
    AgeCategory = "...",
    Id,
    SuTime = "",
    displayValue = "",
    SuDayNumber = "",
    SuMaxChildAmount = "",
    SuCurrentChildAmount = "",
    SuGroupImage = "",
    SuCoachArray = [],
    next_file_url = false,
    file_url = false,
    video_url = []
  } = data;

  const gameTime = SuTime ? moment(SuTime).format("LT") : "...";
  const hasTrainingFiles = next_file_url === false && video_url.length < 1;

  const onCloseUserPopup = () => {
    setIsTrainingClicked(false);
  };

  const handleTrainingClick = () => {
    setIsTrainingClicked(true);
  };

  return (
    <>
      <div className="game-field-head">
        <div className="header">
          <div className="menu">
            <div className="left">
              <div className="team-avatar">
                {SuGroupImage ? <ImageWithCookie src={SuGroupImage} alt="" /> : null}
                {/* <img src={process.env.PUBLIC_URL + "/static/img/game-field-gerb.png"} alt="" /> */}
              </div>
              <div className="team-name">
                <span>{displayValue}</span>
              </div>
              <div className="info">
                <div className="item">
                  <div className="item-icon">
                    <img
                      src={process.env.PUBLIC_URL + "/static/img/select-team-icon-1.png"}
                      alt=""
                    />
                  </div>
                  <div className="item-text">{gameTime}</div>
                </div>
                <div className="item">
                  <div className="item-icon">
                    <img
                      src={process.env.PUBLIC_URL + "/static/img/select-team-icon-2.png"}
                      alt=""
                    />
                  </div>
                  <div className="item-text">{AgeCategory}</div>
                </div>
              </div>
            </div>
            <div className="right">{`${SuMaxChildAmount} / ${SuCurrentChildAmount}`}</div>
          </div>
          <div className="training">
            <button
              className={classNames("training-button", {
                "training-button-disabled": hasTrainingFiles
              })}
              disabled={hasTrainingFiles}
              onClick={handleTrainingClick}
            >
              ТРЕНУВАННЯ
            </button>
          </div>
        </div>
        <div className="couches">
          <div className="couches-holder">
            {SuCoachArray.map((couch = {}, index) => {
              if (couch.SuPosition === null) {
                couch.SuPosition = {};
              }

              const {
                displayValue = "",
                primaryImageValue = "",
                SuPosition: { displayValue: SuPositiondisplayValue = "" } = {}
              } = couch;
              return (
                <div className="item" key={index}>
                  <div className="item-img">
                    {primaryImageValue ? (
                      <ImageWithCookie src={primaryImageValue} alt="" />
                    ) : (
                      <img src={process.env.PUBLIC_URL + "/static/img/user.png"} alt="" />
                    )}
                  </div>
                  <div className="item-info">
                    <div className="item-status">{SuPositiondisplayValue}</div>
                    <div className="item-name">{displayValue}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {isTrainingClicked && (
        <Popup 
          open={isTrainingClicked}
          closeOnDocumentClick
          onClose={onCloseUserPopup}
          lockScroll={true}
        >
          <TrainingPopup
            isTrainingClicked={isTrainingClicked}
            setIsTrainingClicked={setIsTrainingClicked}
            fileTraining={file_url}
            videoTraining={video_url}
            nextFileTraining={next_file_url}
          />
        </Popup>
      )}
    </>
  );
};

export default GameFieldHeader;
